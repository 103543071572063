/* App.css */
body {
  font-family: 'Arial', sans-serif;
  background-image: url('File_Baby_Background.png'); /* Path to your image in the public folder */
  background-size: cover; /* Center the background image */
  background-attachment: fixed;
  background-color: #61dafb;
  margin-left:0;
  margin-right:0;
}

h1 {
  font-size: 1.5em;
  text-align: center;
  color: white;
  margin-left:0;
}

.App-header {
  padding: 0;
  margin: 0;
}


.drag-drop-media-player {
  width: 100%;
  max-width: 100%;
  border: 2px dashed #007bff;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-top: 20px;
  cursor: pointer;
}

.drag-drop-media-player .drop-zone {

  text-align: center;
  color: #333
}

.drag-drop-media-player p {
  margin: 0;
  color: #555;
}
.filebabylarge {
  text-align:center;
  margin-top:50px;
}
.filebabylarge img {
  max-width:250px;
}
.manifest, pre {
  font-size: 10px;
  font-family: "Courier";
  overflow:scroll;
  overflow-scrolling: initial;
  background-color: #f9f9f9;
}